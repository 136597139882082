import { format, parse, isAfter, isSameDay, isSameYear, isSameMonth, isBefore } from 'date-fns'
import cs from 'date-fns/locale/cs'
import { defu } from 'defu'

export function formatDate (dateString, toFmt = 'd. M. yyyy', options = {}) {
  if (!dateString) {
    return ''
  }
  const dateTimeFmt = `yyyy-MM-dd'T'HH:mm:ss`
  const dateTimeZoneFmt = `yyyy-MM-dd'T'HH:mm:ssxxx`
  const dateFmt = 'yyyy-MM-dd'
  try {
    const fromFmt = isDateTime(dateString)
      ? hasTimeZone(dateString)
        ? dateTimeZoneFmt
        : dateTimeFmt
      : dateFmt
    const date = parse(dateString, fromFmt, new Date())
    return format(date, toFmt, defu({ locale: cs }, options))
  } catch (e) {
    return dateString
  }
}

export function formatDateRange (startDateString: string, endDateString: string, hasTime = false): string {
  const startDate = new Date(startDateString)
  const endDate = new Date(endDateString)

  const hasEnd = isAfter(endDate, startDate)
  const hasEndDate = !isSameDay(startDate, endDate) && isBefore(startDate, endDate)

  const startDateFmt = hasEndDate && isSameYear(startDate, endDate)
    ? hasEndDate && isSameMonth(startDate, endDate)
      ? 'd.'
      : 'd. M.'
    : 'd. M. yyyy'

  const startDateStr = formatDate(startDateString, startDateFmt)
  const endDateStr = formatDate(endDateString)

  return `
    ${startDateStr}  ${hasEnd && hasEndDate ? ` - ${hasEndDate ? endDateStr : ''}` : ''}
  `
}

export function formatTimeRange (startDateString: string, endDateString: string): string {
  if (!hasTime(startDateString) && !hasTime(endDateString)) {
    return
  }

  const startDate = new Date(startDateString)
  const endDate = new Date(endDateString)

  const hasEnd = isAfter(endDate, startDate)

  const startTimeStr = formatDate(startDateString, 'HH:mm')
  const endTimeStr = formatDate(endDateString, 'HH:mm')

  const hasStartTime = startTimeStr !== '00:00'
  const hasEndTime = endTimeStr !== '00:00'

  return `
    ${hasStartTime ? startTimeStr : ''}  ${hasEnd && hasEndTime ? ` - ${hasEndTime ? endTimeStr : ''}` : ''}
  `
}

export function isDateTime (dateString: string): boolean {
  return dateString.includes('T')
}

export function hasTimeZone (dateString: string): boolean {
  return dateString.includes('+', 19) || dateString.includes('-', 19)
}

function hasTime (dateString: string) {
  return dateString.includes(':')
}